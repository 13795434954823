/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import useApi from 'common/hooks/useApi'
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from '@material-ui/pickers'
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';

function ProgramChip ({ api, data }) {
    const [athlete, setAthlete] = useState()
    const [program, setProgram] = useState()
    const [programList, setProgramList] = useState([])
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [assignButton, setAssignButton] = useState(null)
    const [open, setOpen] = useState(false);

    const [, programs] = useApi({url: 'metrics/program/'}, {manual: true})

    const handleClickOpen = () => {
        fetchData()
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setAssignButton(false)
    };

    const handleProgramChange = (event: SelectChangeEvent) => {
        setProgram(event.target.value);
    };

    const [{ loading: sendLoading }, sendAssign] = useApi(
        {
            method: 'POST',
            url: 'trainers/program/assign_athlete/',
        },
        { manual: true }
    )

    async function assignProgram() {
        const body = {
            'athlete': data.id,
            'id': program?.id,
            'name': program?.name,
            'tags': program?.tags,
            'start_date': date,
            'action': 'assign',
        }
        await sendAssign({
            data: body,
        })
        data.programs.push(program)
        handleClose()
        if (api){
            api.applyTransaction({ update: [data] })
        }
    }

    const [, removeProgram] = useApi(
            {
              method: 'POST',
              url: 'trainers/program/remove_athlete/',
            },
            { manual: true }
          )

    function deleteProgram(program, id) {

        const body = {
            'id': program.id,
            'athlete': id,
        }
        removeProgram({
            data: body,
        })
        const rowNode = api.getRowNode(data.id);
        let position = data.programs.indexOf(program);
        data.programs.splice(position, 1)
    }

    useEffect(() => {
        if(program){
            setAssignButton(true)
        }
    }, [program])

const fetchData = useCallback(
        async () => {
          const response = await programs()
          setProgramList(response?.data)
        }, [ programs]
    )

  return (
    <span>
        {data?.programs?.map((program) => (<Chip label={program.name} onDelete={() => deleteProgram(program, data?.id)}/>))}
        <Button onClick={handleClickOpen} style={{background: 'teal', color: 'white', minWidth: 30}}>+</Button>
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={'lg'}
            >
            <DialogTitle>Assign Program to {data?.name}</DialogTitle>
          <Box style={{display: 'flex', justifyContent: 'center', padding: '40px'}}>
            <FormControl variant='outlined' style={{paddingRight: '20px', minWidth: '350px', width: '25%'}} >
            <InputLabel >Program</InputLabel>
                <Select
                  value={program}
                  onChange={handleProgramChange}
                >
                  {programList?.map((row) => (
                              <MenuItem value={row}>{row?.name}</MenuItem>
                          ))}
                </Select>
            </FormControl>
            <KeyboardDatePicker
                style={{ paddingRight: '3%',  minWidth: '350px'}}
                format="YYYY-MM-DD"
                inputVariant="outlined"
                label="Start Date"
                name="start-date"
                value={date}
                onChange={(date1, value) => setDate(value)}
            />
            <Button
                variant="contained"
                color="primary"
                style={{height: '55px', width: '100px', marginRight: '20px'}}
                onClick={assignProgram}
                disabled={!assignButton}
              >
              Assign
            </Button>
          </Box>
        </Dialog>
    </span>
  );
};

export default ProgramChip
