import { React, useState, useContext, useEffect, useCallback } from 'react'
import useApi from 'common/hooks/useApi'
import { LayoutContext } from 'common/ducks'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import PeopleIcon from '@material-ui/icons/People';
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import useAppTitle from 'common/hooks/useAppTitle'
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import BtnCellRenderer from './BtnCellRenderer'
import ProgramChip from './ProgramChip'


function Trainers() {

    const [rowData, setRowData] = useState([])
    const defaultColDef = {
        resizeable: true,
        sortable: true,
        filter: true,
        wrapText: true,
        autoHeight: true,
    }
    const [colDefs,] = useState([
        {
            headerName: 'Name',
            field: 'reportLink',
            width: 150,
            cellRenderer: (params) => {
                return <a href={params.data?.reportLink}>{params.data?.name}</a>
            }
        },
        { field: "tags", cellRenderer: BtnCellRenderer, autoSizeColumn: true, flex: 2},
        { field: "momentumScore", width: 175, cellStyle: {textAlign: 'center'} },
        { field: "checkIn", cellDataType: 'dateString', width: 125 },
        { field: "programs", cellRenderer: ProgramChip, autoSizeColumn: true, flex: 2 },
        { field: "programInfo", headerName: 'Recent Activity', flex: 2 },
        { field: "paymentStatus", width: 150 },
    ]);

    useAppTitle('Trainer Dashboard')

    const { setAppLoading } = useContext(LayoutContext)

    const [topCards, settopCards] = useState({awaiting: '', need: '', ready: '',  clientCount: '',})
    const [programList, setProgramList] = useState([])
    const [bulkProgram, setBulkProgram] = useState(null)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [tagList, setTagList] = useState([])
    const [bulkListValue, setBulkListValue] = useState(null)

    const [{ loading: listLoading }, info ] = useApi({url: 'metrics/athlete_info/'}, {manual: true})

    const [, sendBulkAssign] = useApi(
        {
          method: 'POST',
          url: 'trainers/athlete_tags/bulk_assign/',
        },
        { manual: true }
      )

    const [, sendBulkRemove] = useApi(
        {
          method: 'POST',
          url: 'trainers/athlete_tags/bulk_remove/',
        },
        { manual: true }
      )

    const fetchData = useCallback(
        async () => {
          const response = await info()
          setRowData(response?.data.clientInfo)
          settopCards(response?.data.clientPanel)
          setProgramList(response?.data.programs)
          setTagList(response?.data.tagList)
        }, [ info]
    )

    useEffect(() => {
        fetchData()
    }, [fetchData, info])

    useEffect(() => {
        setAppLoading(listLoading)
    }, [listLoading, setAppLoading])

    const TrainerIconDiv = styled.div`
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 100px;
        position: absolute;
        right: 0px;

    `;

    const CardsDiv = styled.div`
        display: flex;
        flex-directions: row;
        justify-content: center;
        gap: 64px;
        padding-top: 60px;
        padding-bottom: 50px;
    `;

    const handleBulkProgramChange = (event: SelectChangeEvent) => {
        setBulkProgram(event.target.value);
    };

    async function bulkAssignProgram() {

        const body = {
            'id': bulkProgram?.id,
            'tags': bulkListValue,
            'start_date': date,
        }
        await sendBulkAssign({
            data: body,
        })
        fetchData()
    }

    async function bulkRemoveProgram() {
        const body = {
            'id': bulkProgram?.id,
            'tags': bulkListValue,
            'start_date': date,
        }
        await sendBulkRemove({
            data: body,
        })
        fetchData()
    }

    return (
        <Trainers.Stylesheet>
            <TrainerIconDiv>
                <Chip label={topCards?.clientCount} color='teal' size="Large" />
            </TrainerIconDiv>

            <CardsDiv>
                <Card className="ready__card">
                    <CardContent style={{ backgroundColor: '#C7E8ED', textAlign: 'center', width: '275px',}}>
                        <PeopleIcon />
                        <Typography>{topCards?.ready}</Typography>
                        <Typography>Athletes Ready!</Typography>
                    </CardContent>
                </Card>
                <Card className="in-need__card">
                    <CardContent style={{ backgroundColor: '#FFF5E5', textAlign: 'center', width: '275px',}}>
                        <PeopleIcon />
                        <Typography>{topCards?.awaiting}</Typography>
                        <Typography>Athletes Awaiting Check-In!</Typography>
                    </CardContent>
                </Card>
                <Card className="alert__card">
                    <CardContent style={{ backgroundColor: '#FF7262', textAlign: 'center', width: '275px',}}>
                        <PeopleIcon />
                        <Typography>{topCards?.need}</Typography>
                        <Typography>Athletes In Need!</Typography>
                    </CardContent>
                </Card>
            </CardsDiv>
            <div
                className="ag-theme-alpine" // applying the Data Grid theme
                style={{ height: 550, margin: '0% 7.5%' }} // the Data Grid will fill the size of the parent container
            >
               <AgGridReact
                   rowData={rowData}
                   columnDefs={colDefs}
                   defaultColDef={defaultColDef}
                   suppressMenuHide='true'
               />
            </div>
            <Card style={{margin: '5% 7.5%'}}>
            <h2 style={{
                    paddingLeft: 30,
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'teal',
                    color: 'white'
                }}> Bulk Program Assign </h2>

            <div style={{padding: '40px', paddingLeft: '15vw', display: 'inline-flex'}}>
                <Autocomplete
                  disablePortal
                  value={bulkListValue}
                  options={tagList}
                  style={{ marginRight: 20, width: 300 }}
                  renderInput={(params) => <TextField {...params}  label="Tags" variant='outlined'/>}
                  onChange={(event, newValue) => {
                      setBulkListValue(newValue);
                    }}
                />
                <FormControl variant='outlined' style={{paddingRight: '20px', minWidth: '40px', width: 300}} >
                <InputLabel >Program</InputLabel>
                    <Select
                      value={bulkProgram}
                      onChange={handleBulkProgramChange}
                    >
                      {programList?.map((row) => (
                                  <MenuItem value={row}>{row?.name}</MenuItem>
                              ))}
                    </Select>
                </FormControl>
                <KeyboardDatePicker
                    style={{ paddingRight: '3%', width: 300}}
                    format="YYYY-MM-DD"
                    inputVariant="outlined"
                    label="Start Date"
                    name="start-date"
                    value={date}
                    onChange={(date1, value) => setDate(value)}
                />
                {bulkListValue && bulkProgram && (
                <>
                <Button
                    variant="contained"
                    color="primary"
                    style={{height: '55px', width: '100px', marginRight: '20px'}}
                    onClick={bulkAssignProgram}
                  >
                  Assign
                </Button>
                <Button
                    variant="contained"
                    style={{height: '55px', width: '100px', backgroundColor: '#FF7262', color: 'white'}}
                    onClick={bulkRemoveProgram}
                  >
                  Remove
                </Button>
                </>
                )}
            </div>
            </Card>
        </Trainers.Stylesheet>
    )
}

Trainers.Stylesheet = styled.div`
    th {
    color: white;
    }

    TableContainer = {
        width: 90%;
        margin: auto;
    }

    .ag-theme-alpine {
    --ag-header-background-color: teal;
    --ag-header-font-color: white;
}
    .ag-theme-alpine .ag-header-cell {
        color: white;
    }
    .ag-body-viewport {
    height: auto !important;
}

    .ag-theme-alpine .ag-icon-desc::before
{
    color: white;
}

.ag-theme-alpine .ag-icon-asc::before
{
    color: white;
}

.ag-theme-alpine .ag-icon-menu::before
{
    color: white;
}
`

export default Trainers
