/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import useApi from 'common/hooks/useApi'
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';

function BtnCellRenderer ({ api, data }) {

    const [athlete, setAthlete] = useState()
    const [tag, setTag] = useState(null)
    const [tagList, setTagList] = useState([])
    const [assignButton, setAssignButton] = useState(null)
    const [open, setOpen] = useState(false);

    const [, tags] = useApi({url: 'trainers/athlete_tags/tag_list/'}, {manual: true})

    const fetchData = useCallback(
        async () => {
          const response = await tags()
          setTagList(response?.data?.tagList)
        }, [ tags]
    )

    const handleClickOpen = () => {
        fetchData()
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setAssignButton(false)
    };

    const handleProgramChange = (event: SelectChangeEvent) => {
        setProgram(event.target.value);
    };

    const [, addTag] = useApi(
        {
          method: 'POST',
          url: 'trainers/athlete_tags/add_tag/',
        },
        { manual: true }
    )

    const [, removeTag] = useApi(
            {
              method: 'POST',
              url: 'trainers/athlete_tags/remove_tag/',
            },
            { manual: true }
          )

    function addTagFunction() {
        const body = {
            'tags': tag,
            'id': data.id,
        }
        addTag({
            data: body,
        })
        data.tags.push(tag)
        handleClose()
        if (api){
            api.applyTransaction({ update: [data] })
        }
    }

    function deleteTag(tag, id) {
        const body = {
            'tags': tag,
            'id': id,
        }
        removeTag({
            data: body,
        })
        const rowNode = api.getRowNode(data.id);
        let position = data.tags.indexOf(tag);
        data.tags.splice(position, 1)
    }

    function handleAutocompleteChange(event, value) {
        if (value){
        setTag(value)
        setAssignButton(true)
        }
    }

  return (
    <span>
        {data?.tags?.map((tag) => (<Chip label={tag} onDelete={() => deleteTag(tag, data?.id)}/>))}
        <Button onClick={handleClickOpen} style={{background: 'teal', color: 'white', minWidth: 30}}>+</Button>

        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={'lg'}
            >
          <DialogTitle>Assign Tag to {data?.name}</DialogTitle>
          <Box style={{display: 'flex', justifyContent: 'center', padding: '40px'}}>

            <Autocomplete
                    options={tagList}
                    onInputChange={handleAutocompleteChange}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Tags"
                            name="tags"
                            type='text'
                            variant='outlined'
                            value={tag}
                        />}
                    value={tag}
                    style={{width: 300, marginRight: 20}}
                  />
            <Button
                variant="contained"
                color="primary"
                style={{height: '55px', width: '100px', marginRight: '20px'}}
                onClick={addTagFunction}
                disabled={!assignButton}
              >
              Assign
            </Button>
          </Box>
        </Dialog>
    </span>
  );
};

export default BtnCellRenderer
