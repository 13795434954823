import React from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useApi from 'common/hooks/useApi'


function CopyRenderer ({ api, data }) {

  const [, copyProgExercise] = useApi(
            {
              method: 'POST',
              url: 'builder/program_exercises/copy_program_exercise/',
            },
            { manual: true }
          )

  async function handleCopy() {
    const body = {id: data.id, block_id: data.block_id}
    let response = await copyProgExercise({
        data: body,
    })
    if (response?.status === 200) {
        response = response?.data
        api.applyTransaction({ add: [{
            id: response?.id,
            exercise: response?.exercise?.name,
            exercise_pk: response?.exercise?.id,
            set_number: Number(response?.setNumber),
            weight_lbs: Number(response?.weightLbs),
            distance_meters: Number(response?.distance),
            reps: Number(response?.reps),
            reps_in_reserve: Number(response?.repsInReserve),
            one_rep_max_percent: Number(response?.oneRepMaxPercent),
            tempo: response?.tempo,
            work_time_seconds: Number(response?.workTimeSeconds),
            rest_time_seconds: Number(response?.restTimeSeconds),
            notes: response?.notes,
            kpi: response?.kpi,
            position: response?.position,
            block_id: data?.block_id,
            value: response?.exercise,
            }]
        })
    }
  }

  return (
    <span>
      <ContentCopyIcon style={{ color: "teal" }} onClick={handleCopy} />
    </span>
  );
};

export default CopyRenderer
